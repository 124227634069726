<template>
  <div>
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Nova plataforma</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>
    </div>

    <div class="container fd-cadastro">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-9">
          <div class="fd-cadastro-form-header">
            <h1>Comece sua plataforma hoje mesmo!</h1>
          </div>
          <form id="formcadastroplataforma">
            <div class="messages" />
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-4">
                <div class="fd-cadastro-form text-center">
                  <img v-lazy="imageSiteOndemandIcon">
                  <br>
                  <small>Plataforma {{ nomePlataforma }}</small>
                </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="form-group">
                  <label for="nome_plataforma">* Nome da sua plataforma</label>
                  <input
                    id="nome_plataforma"
                    v-model="nomePlataforma"
                    type="text"
                    class="form-control form-control-lg"
                    maxlength="30"
                    placeholder="Qual será o nome da sua plataforma?"
                  >
                  <div
                    class="help-block with-errors"
                    style="color:red"
                  />
                </div>
                <div
                  v-if="plataformasMae.length > 0"
                  id="divSelectPlataformaMae"
                  class="form-group"
                >
                  <label for="nome_plataforma_mae">Plataforma mãe</label>
                  <select
                    id="selectPlataformaMae"
                    v-model="plataformaMaeEscolhida"
                    class="form-control form-control-lg"
                  >
                    <option
                      value="0"
                      selected="selected"
                    >
                      -- Escolha uma plataforma mãe ---
                    </option>
                    <option
                      v-if="adminFast"
                      value="1287"
                    >
                      FastEAD
                    </option>

                    <option
                      v-for="p in plataformasMae"
                      :key="p.id_plataforma"
                      :value="p.id_plataforma"
                    >
                      {{ p.nome_plataforma }}
                    </option>
                  </select>
                </div>

                <div
                  v-else
                  id="divSelectPlataformaMae"
                  class="form-group"
                >
                  <label for="nome_plataforma_mae">Plataforma mãe</label>
                  <select
                    id="selectPlataformaMae"
                    v-model="plataformaMaeEscolhida"
                    class="form-control form-control-lg"
                  >
                    <option value="0">
                      FastEAD
                    </option>
                  </select>
                </div>

                <div
                  v-if="adminFast"
                  class="mt-4 d-none"
                >
                  <label><input
                    v-model="pessoaResponsavel.configurar"
                    type="checkbox"
                  > Definir a pessoa da plataforma  </label>
                
                  <div
                    v-if="pessoaResponsavel.configurar"
                    class="row"
                  >
                    <div class="col-sm-12 col-md-12 col-lg-8">
                      <input
                        v-model="pessoaResponsavel.email"
                        type="email"
                        class="form-control form-control-lg"
                        placeholder="Informe o email da pessoa"
                      >
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 p-2 text-center">
                      <button
                        class="btn btn-primary"
                        @click.prevent="getPessoaResponsavel()"
                      >
                        Buscar pessoa
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="pessoaResponsavel.id_pessoa && pessoaResponsavel.configurar"
                    class="row mt-4"
                  >
                    <div class="col-12 text-center">
                      <b>Pessoa escolhida</b>
                      <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(pessoaResponsavel.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />
                      <div>
                        {{ pessoaResponsavel.first_name + " " + pessoaResponsavel.last_name }}
                      </div>
                      <div>
                        {{ pessoaResponsavel.email }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 text-center">
              <input
                id="Salvar"
                type="submit"
                class="btn btn-primary btn-lg"
                value="Criar plataforma!"
                @click.prevent="CadastraPlataforma"
              >
            </div>
          </form>
        </div>
      </div>
      <div class="fd-cadastro-form-footer">
        <div class="mt-3 text-center">
          <img v-lazy="imageSiteFooterIcon">
          <small class="text-muted">Todos os direitos reservados.</small>
        </div>
      </div>
    </div>

    <!-- Modal de erro -->
    <modal
      name="modalStatus"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <!-- Esse comentário desabilita a verificação do eslint para v-html (considerado perigoso devido ao XSS)-->
      <!-- eslint-disable -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-html="modal_titulo_erro" />
        </div>
        <div class="modal-body" v-html="modal_html_erro" />
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal('modalStatus')">Fechar</button>
        </div>
      </div>
      <!-- eslint-enable -->
    </modal>
    <!-- Modal de erro -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import imageSiteOndemandIcon from "@/assets/images/app/ondemand-icon.png";
import imageSiteFooterIcon from "@/assets/images/footer-logo.png";

export default {
  name: "HomeInternoCadastroPlataforma",
  mixins: [methods],
  data() {
    return {
      nomeUsuario: this.retornaSessao(settings.fastSessaoUsuario).First_name,
      imageSiteOndemandIcon,
      imageSiteFooterIcon,
      nomePlataforma: "",
      html_erro: "",
      plataformasMae: [],
      plataformaMaeEscolhida: "0",
      modal_titulo_erro: "",
      modal_html_erro: "",
      emailResponsavel: "",
      adminFast: false,
      pessoaResponsavel: {
        id_pessoa: 0,
        first_name: "",
        last_name: "",
        email: "",
        image: "",
        configurar: false
      }
    };
  },
  mounted: function () {
    this.buscaPlataformasAdministradas().then((obj) => {
      this.$store.state.fastCarregando = false;
    });
  },
  methods: {
    buscaPlataformasAdministradas() {
      return fetch(
        `${settings.endApiFastEad}api/fast_plataforma/lista_administradas`,
        this.fastAjaxOptions("GET")
      )
        .then((resp) => resp.json())
        .then((obj) => {

          if (this.getFastSessao(settings.fastSessaoMinhasPlataformas)) {
            if (
              this.getFastSessao(settings.fastSessaoMinhasPlataformasLista)[0]
            ) {
              if (
                this.getFastSessao(settings.fastSessaoMinhasPlataformasLista)[0]
                  .admin_fast == "S"
              )
                this.adminFast = true;
            }
          }

          this.adminFast = this.getFastSessao(settings.fastSessaoUsuarioAdminFast);

          return (this.plataformasMae = obj.filter((o) => {
            if (o.nome_plataforma != "admin") return o;
          }));

          this.plataformaMaeEscolhida = this.plataformasMae[0].id_plataforma;
        })
        .catch((e) => console.log(e));
    },
    CadastraPlataforma() {
      console.log("CadastraPlataforma", this.nomePlataforma);
      // Validação
      if (this.nomePlataforma == "") {
        this.modal_titulo_erro =
          '<span class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Erro ao cadastrar</span>';
        this.modal_html_erro = "O nome da plataforma não pode ficar em branco";
        this.showModal("modalStatus");
      } else if (this.nomePlataforma == "admin") {
        this.modal_titulo_erro =
          '<span class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Erro ao cadastrar</span>';
        this.modal_html_erro =
          "O nome admin está bloqueado para uso. Escolha um outro nome.";
        this.showModal("modalStatus");
      } else if (parseInt(this.plataformaMaeEscolhida) < 1) {
        this.modal_titulo_erro =
          '<span class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Erro ao cadastrar</span>';
        this.modal_html_erro = "Você deve definir uma plataforma mãe";
        this.showModal("modalStatus");
      } else {
        // Monta objeto para ser enviado via POST
        this.$store.state.fastCarregando = true;
        let fast_plataforma_usuario = {
          id_plataforma: 0,
            nome_usuario:
              this.retornaSessao(settings.fastSessaoUsuario).First_name +
              " " +
              this.retornaSessao(settings.fastSessaoUsuario).Last_name,
            email: this.retornaSessao(settings.fastSessaoUsuario).Email,
            id_pessoa: this.pessoaResponsavel ? this.pessoaResponsavel.id_pessoa : 0,
            fast_plataforma: {
              id_plataforma: 0,
              nome_plataforma: this.nomePlataforma,
              url_plataforma:
                settings.endFastEad + "plataformas/" + this.nomePlataforma,
              plataforma_mae: this.plataformaMaeEscolhida,
            },
          };

        fetch(
          `${settings.endApiFastEad}api/fast_plataforma/insere`,
          this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario))
        )
          .then((resp) => resp.json())          
          .then((obj) => {
            console.log(obj);            
              if (obj[0]) {
                //this.getRefreshToken().then(() => window.location.href = "/minhas-plataformas?novaPlataforma=" + this.nomePlataforma)   
                sessionStorage.setItem(settings.fastSessaoPermissoes, null)
                window.location.href = "/plataforma/" + obj[0].id_plataforma          
              } else {
                this.modal_titulo_erro =
                  '<span class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Erro ao cadastrar</span>';

                this.showModal("modalStatus");
                this.$store.state.fastCarregando = false;
              }
            
          })
          .catch((e) => {
            console.log(e);
            this.$store.state.fastCarregando = false;
          });
      }
    },
    async getPessoaResponsavel(){
      if (/\S+@\S+\.\S+/.test(this.pessoaResponsavel.email.trim())) {
        this.promiseGetFastApi("api/fast_plataforma_usuario/lista_pessoas", "email=" + this.pessoaResponsavel.email.trim()).then(obj => {     
          console.log("getPessoaResponsavel", obj);     
          if (obj) {            
            this.pessoaResponsavel.id_pessoa = JSON.parse(obj).id_pessoa
            this.pessoaResponsavel.first_name = JSON.parse(obj).first_name
            this.pessoaResponsavel.last_name = JSON.parse(obj).last_name
            this.pessoaResponsavel.image = JSON.parse(obj).image
            this.pessoaResponsavel.email = JSON.parse(obj).email
          } else {
            this.exibeToasty("Pessoa não encontrada", "error");
          }
        }).catch(e => {
          console.log(e);
        })
      } else {
        this.exibeToasty("Informe um email válido", "error");
      }
    }
  },
};
</script>

<style></style>
